import { navigate } from "gatsby";

// Hostnet:
// zupr.dk 		(Denemarken - Engels)
// zupr.cz 		(Tsjechië - Engels)
// zupr.lu 		(Luxemburg - Engels)
// zupr.pl 		(Polen - Engels)

// Neostrada:
// zupr.at 		(Oostenrijk - Duits)
// zupr.be 		(België - Nederlands)
// zupr.ch 		(Zwitserland - Engels —> Frans, Duits, Italiaans)
// zupr.co.uk 	(UK - Engels / we hebben ook zupr.uk)
// zupr.de 		(Duitsland - Duits)
// zupr.es		(Spanje - Engels —> Spaans)
// zupr.fr		(Frankrijk - Engels —> Frans)
// zupr.gr		(Polen - Engels) (met dit domein doen we allemaal dingen geloof ik)
// zupr.eu		(EU - Engels)
// zupr.it		(Italië - Engels —> Italiaans toevoegen)
// zupr.pt		(Portugal - Engels)
// zupr.se		(Zweden - Engels)

const en = [
    "zupr.dk",
    "zupr.cz",
    "zupr.lu",
    "zupr.pl",
    "zupr.at",
    "zupr.ch",
    "zupr.co.uk",
    "zupr.es",
    "zupr.fr",
    "zupr.gr",
    "zupr.eu",
    "zupr.it",
    "zupr.pt",
    "zupr.se",
];
const nl = ["zupr.be"];
const de = ["zupr.de"];
const es = [];

const Page = ({ location }) => {
    if (location.hostname) {
        if (en.some((hostname) => location.hostname.includes(hostname))) {
            navigate("/en/storelocator/");
            return null;
        }
        if (nl.some((hostname) => location.hostname.includes(hostname))) {
            navigate("/nl/storelocator/");
            return null;
        }
        if (de.some((hostname) => location.hostname.includes(hostname))) {
            navigate("/de/storelocator/");
            return null;
        }
        if (es.some((hostname) => location.hostname.includes(hostname))) {
            navigate("/en/storelocator/");
            return null;
        }
        navigate("/nl/");
    }

    return null;
};

export default Page;
